.dropzone-container {
    align-items: center;
    border: 2px dashed gray;
    border-radius: 5px;
    color: #2C325872;
    cursor: pointer;
    height: 140px;
    justify-content: center;
    margin: 50px 0;
    max-width: 700px;
    outline: none;
    padding: 20px;
    width: 80%;
    transition: all .2s linear;
}

.dropzone-container:hover {
    background-color: rgba(21, 171, 53, 0.15);
}

.dropzone-container section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.dropzone-container section div {
    margin: 5px;
}

.disabled-dropzone {
    display: none;
}

.enabled-dropzone {
    border-color: #15ab35;
    display: block;
}

.dropzone-error-message {
    color: #cf3f4f;
}

.hidden-dropzone {
    display: none;
}

.active-dropzone {
    display: block;
}

@media only screen and (max-width: 700px) {
    .dropzone-container {
        width: calc(100% - 40px);
        margin: 20px;
    }

    .dropzone-container section div {
        margin: 5px 0;
    }
}