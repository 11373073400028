.result-page main {
    flex-direction: column;
}

h3 {
    font-size: 1.3em;
}

.legend {
    display: flex;
    margin: 20px 0;
    align-items: center;
}

.legend .legend-param {
    width: calc(100%/3);
    margin-right: 20px;
    padding: 5px;
    border-radius: 5px;
}

.legend .legend-param div {
    height: 35px;
    width: 100%;
    margin-right: 30px;
    margin-top: 10px;

}

.bleed {
    border: 3px solid #009de0;
}

.safe-area {
    border: 3px solid #289548;
}

.app .legend .cut-line {
    border: 3px solid #bc2f7d;
}

.note {
    color: #cf3f4f;
}

.btns-wrapper {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin: 30px 0;
}

.action-btns {
    display: flex;
}

.action-btns .MuiButton-root {
    margin-right: 20px;
}

.action-btns a {
    text-decoration: none;
}

.result-page h3 .line {
    display: block;
    margin: 20px 0;
}

.legend h4 {
    margin-right: 20px;
    margin-top: 0;
}

@media only screen and (max-width: 700px)  {
    .legend .legend-param {
        margin-right: 10px
    }
}