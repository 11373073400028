.error-result-wrapper {
    border: 3px solid hsl(0, 0%, 50%);
    padding: 20px;
    border-top: none;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.error-result h3 {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
    padding: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #cf3f4f;
}

.error-result h3.result-type-warning {

}

.error-result h3.result-type-warning {
    background-color: #f1c232;
}

.error-result h3 .MuiSvgIcon-root {
    font-size: 30px;
    margin-right: 10px;
}

.error-message {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: 3px solid #cf3f4f;
}

.warning-message {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: 3px solid #f1c232;
}

.error-message .MuiSvgIcon-root {
    color: #cf3f4f;
    font-size: 80px;
    margin-right: 10px;
}

.warning-message .MuiSvgIcon-root {
    color: #f1c232;
    font-size: 80px;
    margin-right: 10px;
}

.error-list {
    overflow-y: auto;
    max-height: 500px;
    scrollbar-width: thin;
    width: 25%;
    margin-right: 15px;
}

.artwork-result {
    position: relative;
    width: 75%;
}

.bbox-wrapper {
    position: absolute;
}

.result-img {
    height: 500px;
}

.error-result-container {
    display: flex;
    margin: 10px 0;
}

@media only screen and (max-width: 700px)  {
    .error-list {
        width: 100%;
        overscroll-behavior: contain;
        overflow-y: auto;
        max-height: 220px;
    }

    .error-result-container {
        flex-direction: column;
    }

    .artwork-result {
        width: 100%;
    }

    .error-result-wrapper {
        padding: 5px;
    }
}