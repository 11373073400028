.MuiButton-root{
    height: 50px;
    padding: 5px 15px;
    font-size: 0.8em;
    border-radius: 5px;
    transition: all .7s ease-in-out;;
    cursor: pointer;
    border: 1px solid hsl(0, 0%, 80%);
    background-color: white;
    color: var(--base-font-color);
}
.MuiButton-root:hover {
     background-color: hsl(0, 0%, 80%);
 }
.Mui-disabled:hover{
    background-color: white;
    cursor: no-drop;
}

.active-btn {
    display: block;
}

.hidden-btn {
    display:none;
}

@media only screen and (max-width: 700px) {
    .MuiButton-root{
        font-size: .6em;
        padding: 5px 10px;
    }

    .action-btns .MuiButton-root{
        margin-right: 10px;
    }
}