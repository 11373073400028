.bbox {
    border-radius: 1px;
    position: absolute;
}

.bbox.warning {
    z-index: 8;
}

.bbox.error {
    z-index: 9;
}

.bbox.warning.selected {
    background-color: #dec68380;
    border: 0.5px solid #dec68380;
}

.bbox.error.selected {
    background-color: #97202d96;
    border: 0.5px solid #97202d96;
}