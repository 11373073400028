:root {
  --base-font-color: #2c3258;
}

html,
body {
  min-height: 100%;
  margin: 0;
  font-family: Arial;
  color: var(--base-font-color);
}