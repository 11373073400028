.error .MuiSvgIcon-root {
    color: #cf3f4f;
}

.warning .MuiSvgIcon-root {
    color: #f1c232;
}

.selected-error {
    background-color: #cf3f4f29;
    border: 1px solid #cf3f4f;
}

.selected-warning {
    background-color: #fff8e5;
    border: 1px solid #f1c232;
}

.error-item {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}

.error-item:hover {
    background-color: #f1f1f1;
}

.error-item .MuiSvgIcon-root {
    margin-right: 10px;
    font-size: 50px;
}

.error-short-description {
    font-size: 0.9em;
}

@media only screen and (max-width: 700px)  {
    .error-item {
        margin: 10px 0;
    }
}