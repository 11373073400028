.app {
    background-color: #ffffff;
    font-size: 1.3em;
}

.app-wrapper {
    margin: 0 20px 20px 20px;
}

h1 {
    margin: 0;
    font-size: 1.25em;
}

h2 {
    font-size: 1.35em;
    margin-bottom: 30px;
}

h3 {
    font-size: 1.1em;
}
h4 {
    margin: 20px 0 0 0;
}

main {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 700px)  {
    main {
        flex-direction: column;
    }

    h2 {
        margin-bottom: 25px;
    }
}