.app-header {
    background-color: var(--base-font-color);
    color: white;
    padding: 20px;
    cursor: default;
}

.select-label {
    margin-bottom: 20px;
    display: block;
}

.user-info {
    width: 30%;
    margin-right: 190px;
}

.load-doc {
    width: 70%;
}

.load-doc div {
    margin-bottom: 20px;
}

.result-page-unloaded, .disabled-upload-page, .disabled-loading-line {
    display: none;
}

.result-page-loaded, .enabled-upload-page, .active-loading-line {
    display: block;
}

.select-shape .select-shape-of__control, .select-shape .select-shape-of__single-value {
    color: var(--base-font-color);
}

.select-shape .select-shape-of__control--is-focused, .select-shape .select-shape-of__control:hover {
    border: 2px solid #000000;
    box-shadow: none;
}

.loading-status {
    white-space: pre-wrap;
}

@media only screen and (max-width: 700px)  {
    .user-info {
        width: 100%;
        margin-right: 0;
    }

    .load-doc {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}