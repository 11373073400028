.success-result-wrapper{
    border: 3px solid hsl(0, 0%, 50%);
    padding: 20px;
    border-top: none;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.success-result h3{
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
    padding: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #f1c232;
}
.success-result h3 .MuiSvgIcon-root{
    font-size: 30px;
    margin-right: 10px;
}
.success-message{
    display: flex;
    padding: 10px;
    border-radius: 5px;
    border: 3px solid #f1c232;
}
.success-message .MuiSvgIcon-root{
    color: #f1c232;
    font-size: 80px;
    margin-right: 10px;
}
.success-result .artwork-result{
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
}